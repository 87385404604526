const SVG_PATH_REGEX = /^<path\s+[^>]*d="[^"]*"[^>]*\/>$/

export function createTrustedHTML(svg: string): string {
  // Validate that the input only contains safe SVG path elements
  if (!SVG_PATH_REGEX.test(svg)) {
    console.error("Invalid SVG path format")
    return ""
  }

  // Additional sanitization if needed
  const sanitized = svg
    .replace(/[<>]/g, (match) => ({ "<": "&lt;", ">": "&gt;" })[match] || "")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")

  return sanitized
}
